<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <KTCard>
                    <template v-slot:title>
                        {{operationTitle}}
                        <p><small>View, Add, Edit and Delete Halls and Slots</small></p>
                    </template>
                    <template v-slot:toolbar>
                        <b-input-group-append>
                            <router-link to="/halls/create"
                                         v-if="globalPropertyId > 0 && $global.hasPermission('hallsstore')"
                            >
                                <b-button class="btn btn-primary font-weight-bolder"
                                          v-b-tooltip.hover :title="`Add New`"
                                          variant="success"><i class="fa fa-plus fa-sm"></i> Add New
                                </b-button>
                            </router-link>
                            <!--                            <b-button variant="info"-->
                            <!--                                      class="ml-2 filter-button"-->
                            <!--                                      v-b-tooltip.hover :title="`Filter`"-->
                            <!--                                      @click="filters.visible = !filters.visible">-->
                            <!--                                <i class="fa fa-filter fa-sm"></i> Filter-->
                            <!--                            </b-button>-->
                        </b-input-group-append>
                    </template>

                    <template v-slot:body>
                        <v-card>
                            <div class="halls-table"
                                 v-if="$global.hasPermission('hallsview')">

                                <div class="w-100 mb-6">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="dataTables_length"><label>Show
                                                <b-form-select v-model="pagination.totalPerPage"
                                                               class="datatable-select"
                                                               :options="[10, 50, 100]"
                                                               size="sm"></b-form-select>
                                                entries</label></div>

                                        </div>
                                        <!--                                        <div class="col-sm-12 col-md-6 mt-2">-->
                                        <!--                                            <span v-for="(value,key) in filters"-->
                                        <!--                                                  :key="key">-->
                                        <!--                                                <span v-if="_.isArray(value) && key !== 'visible'">-->
                                        <!--                                                    <b-tag class="py-2 text-capitalize" v-if="value.length > 0"> {{$global.removeSymbols(key)}}-->
                                        <!--                                                        <i class="fa fa-times-circle ml-2"-->
                                        <!--                                                           @click="clearFilter(key, [])"></i>-->
                                        <!--                                                    </b-tag>-->
                                        <!--                                                </span>-->
                                        <!--                                                <span v-else>-->
                                        <!--                                                    <b-tag class="py-2"-->
                                        <!--                                                           v-if="value && key !== 'visible'"> <span-->
                                        <!--                                                        class="text-capitalize">{{$global.removeSymbols(key)}}</span>-->
                                        <!--                                                    <i class="fa fa-times-circle ml-2"-->
                                        <!--                                                       @click="clearFilter(key , null)"></i>-->
                                        <!--                                                    </b-tag>-->
                                        <!--                                                </span>-->
                                        <!--                                            </span>-->
                                        <!--                                        </div>-->
                                        <div class="col-sm-12 col-md-6">
                                            <div id="kt_datatable_filter" class="dataTables_filter"><label>Search:
                                                <b-form-input type="search"
                                                              class="form-control form-control-sm ml-2 w-25"
                                                              label="Search"
                                                              v-on:keyup.enter="handleSearch"
                                                              v-model="search"></b-form-input>
                                            </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <v-data-table
                                    responsive
                                    ref="table"
                                    :headers="columns"
                                    :items="dataSource"
                                    :loading="loading"
                                    :items-per-page="parseInt(pagination.totalPerPage)"
                                    hide-default-footer
                                    class="table table-head-custom table-vertical-center table-responsive"

                                >
                                    <template v-slot:item.id="record">
                                        {{dataSource.length - record.index}}
                                    </template>
                                    <template v-slot:item.max_capacity="record">
                                        {{ record.item.min_capacity}} - {{record.item.max_capacity}}
                                    </template>
                                    <template v-slot:item.hallSlots="record">
                                        <b-button size="sm" variant="primary"
                                                  v-show="record.item.hallSlots"
                                                  @click="handleHollSlots(record.item.hallSlots)"
                                                  v-b-modal.hall-slots>
                                            Show Hall Slots
                                        </b-button>
                                    </template>
                                    <template v-slot:item.action="record" class="action-column">
                                        <router-link :to="`halls/edit/${record.item.id}`">
                                            <a size="sm" class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                               v-show="$global.hasPermission('hallsupdate')" href="javascript:;">
                                                <span
                                                    class="svg-icon svg-icon-md svg-icon-primary">
                                                  <!--begin::Svg Icon-->
                                                  <inline-svg
                                                      src="/media/svg/icons/Communication/Write.svg"
                                                      class="action-edit-button"
                                                  ></inline-svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </a>
                                        </router-link>
                                        <a size="sm" class="ml-2 btn btn-icon btn-light btn-hover-primary btn-sm"
                                           v-show="$global.hasPermission('hallsdestroy')"
                                           @click="handleDeleteOperation(record.item.id)">
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary">
                                              <!--begin::Svg Icon-->
                                              <inline-svg
                                                  src="/media/svg/icons/General/Trash.svg" class="action-delete-button"
                                              ></inline-svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </a>
                                    </template>
                                </v-data-table>
                                <div class="row">
                                    <div class="pagination-box">
                                        <b-pagination
                                            v-model="pagination.current"
                                            :total-rows="pagination.total"
                                            :per-page="pagination.totalPerPage"
                                            size="lg"
                                        ></b-pagination>

                                    </div>
                                </div><!-- /.pull-right -->
                            </div>
                        </v-card>
                    </template>
                </KTCard>
                <div class="filter-container">
                    <div id="filter_list"
                         ref="company_profiles_list"
                         v-show="!operation &&filters.visible"
                         :class="filters.visible ? 'offcanvas offcanvas-right p-10 offcanvas-on' :'offcanvas offcanvas-right p-10'"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12" class="text-right">
                                    <a
                                        href="javascript:;"
                                        @click="filters.visible = !filters.visible"
                                        class="btn btn-xs btn-icon btn-light btn-hover-primary text-right"
                                    >
                                        <i class="ki ki-close icon-xs text-muted"></i>
                                    </a>
                                </b-col>
                                <b-col sm="12">
                                    <b-form-group
                                        label="Name Of Hall"
                                        label-for="name">
                                        <b-form-input id="filter-name" v-model="filters.name"
                                                      class="mb-2"></b-form-input>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button @click="handleResetFilterClick()" size='sm' variant="info">
                                    Clear All
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </div>
                </div>
                <!-- /.filter-container -->
            </div>
        </div>
        <b-modal id="hall-slots" ok-only ok-title="cancel">
            <div class="table-responsive">
                <table class="table table-bordered table-hover" v-show="hallSlot">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Slot</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(slot,index) in hallSlot" :key="index">
                        <td>{{index+1}}</td>
                        <td>{{slot.slot}}</td>
                        <td>{{slot.start_time}}</td>
                        <td>{{slot.end_time}}</td>
                        <td>{{slot.status ? "Active" : "DeActive"}}</td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </b-modal>

    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import KTCard from "@/view/content/Card.vue";
    import ListingMixin from "@/assets/js/components/ListingMixin";
    import ModuleMixin from "./module.mixin"

    export default {
        mixins: [ListingMixin, ModuleMixin],
        data() {
            return {}
        },
        components: {
            KTCard,
        },
        computed: {
            ...mapState([
                'global',
            ]),
        },
    }
</script>
